import { createTheme } from "@mui/material/styles";
var shadowDP4 = "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)";
var theme = function (primaryColor) {
    return createTheme({
        palette: {
            primary: {
                main: primaryColor,
            },
            background: {
                default: "#f4f7f8",
            },
        },
        components: {
            MuiButton: {
                defaultProps: {
                    disableElevation: true,
                },
                styleOverrides: {
                    contained: {
                        textTransform: "capitalize",
                    },
                },
            },
            MuiPaper: {
                defaultProps: {
                    elevation: 0,
                    square: true,
                },
            },
            MuiTextField: {
                defaultProps: {
                    size: "small",
                    InputLabelProps: {
                        shrink: true,
                    },
                    variant: "outlined",
                },
            },
        },
        shadows: [
            "none",
            "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
            "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
            "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
            shadowDP4,
            shadowDP4,
            shadowDP4,
            shadowDP4,
            shadowDP4,
            shadowDP4,
            shadowDP4,
            shadowDP4,
            shadowDP4,
            shadowDP4,
            shadowDP4,
            shadowDP4,
            shadowDP4,
            shadowDP4,
            shadowDP4,
            shadowDP4,
            shadowDP4,
            shadowDP4,
            shadowDP4,
            shadowDP4,
            shadowDP4,
        ],
    });
};
export { theme };
