var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { CssBaseline, ThemeProvider } from "@mui/material";
import React from "react";
import ReactDOM from "react-dom";
import { theme } from "theme";
/**
 * Dynamically import the React component described by the container element
 * https://webpack.js.org/guides/dependency-management
 */
(function (context, containers) {
    containers.forEach(function (container) {
        context.keys().forEach(function (key) {
            var component = container.dataset.component;
            if (key.includes(component)) {
                var Component = context(key)[component];
                delete container.dataset.component;
                ReactDOM.render(React.createElement(ThemeProvider, { theme: theme(brandingConfiguration.primaryColor) },
                    React.createElement(CssBaseline, null),
                    React.createElement(Component, __assign({}, props[component]))), container);
            }
        });
    });
})(require.context("./containers", true, /.ts$/), document.querySelectorAll("[data-component]"));
