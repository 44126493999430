import React from "react";
import { Step, StepLabel, Stepper, StepConnector, stepConnectorClasses, Typography, } from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { ThemeProvider, createTheme, styled } from "@mui/material/styles";
var darkTheme = createTheme({
    palette: {
        primary: {
            main: "#fff",
        },
        mode: "dark",
    },
    typography: { fontSize: 17 },
});
var StyledConnector = styled(StepConnector)(function (_a) {
    var _b, _c, _d;
    var theme = _a.theme;
    return (_b = {},
        _b["& .".concat(stepConnectorClasses.line)] = {
            borderColor: theme.palette.primary.main,
            borderTopWidth: 3,
            borderRadius: 5,
            opacity: 0.2,
        },
        _b["&.".concat(stepConnectorClasses.active)] = (_c = {},
            _c["& .".concat(stepConnectorClasses.line)] = {
                opacity: 1,
            },
            _c),
        _b["&.".concat(stepConnectorClasses.completed)] = (_d = {},
            _d["& .".concat(stepConnectorClasses.line)] = {
                opacity: 1,
            },
            _d),
        _b);
});
var DesktopStepper = function (_a) {
    var stepperTitle = _a.stepperTitle, currentStepLabel = _a.currentStepLabel, completedStepLabel = _a.completedStepLabel, activeStep = _a.activeStep, signUpTitle = _a.signUpTitle, verifyEmailTitle = _a.verifyEmailTitle, accountDetailsTitle = _a.accountDetailsTitle, verifyingAccountTitle = _a.verifyingAccountTitle;
    return (React.createElement(ThemeProvider, { theme: darkTheme },
        React.createElement(Stepper, { activeStep: activeStep, connector: React.createElement(StyledConnector, null), component: "ol", "aria-label": stepperTitle },
            React.createElement(Step, { sx: { paddingLeft: 0 }, component: "li" },
                React.createElement(StepLabel, null,
                    activeStep === 0 && (React.createElement(Typography, { component: "span", sx: visuallyHidden }, currentStepLabel)),
                    activeStep > 0 && (React.createElement(Typography, { component: "span", sx: visuallyHidden }, completedStepLabel)),
                    signUpTitle)),
            React.createElement(Step, { component: "li" },
                React.createElement(StepLabel, null,
                    activeStep === 1 && (React.createElement(Typography, { component: "span", sx: visuallyHidden }, currentStepLabel)),
                    activeStep > 1 && (React.createElement(Typography, { component: "span", sx: visuallyHidden }, completedStepLabel)),
                    verifyEmailTitle)),
            React.createElement(Step, { component: "li" },
                React.createElement(StepLabel, null,
                    activeStep === 2 && (React.createElement(Typography, { component: "span", sx: visuallyHidden }, currentStepLabel)),
                    activeStep > 2 && (React.createElement(Typography, { component: "span", sx: visuallyHidden }, completedStepLabel)),
                    accountDetailsTitle)),
            React.createElement(Step, { component: "li" },
                React.createElement(StepLabel, null,
                    activeStep === 3 && (React.createElement(Typography, { component: "span", sx: visuallyHidden }, currentStepLabel)),
                    verifyingAccountTitle)))));
};
export { DesktopStepper };
