import { List, ListItem, Link, Typography, Stack, Container, Paper, } from "@mui/material";
import React from "react";
var GlobalFooter = function (_a) {
    var links = _a.links, legalInformation = _a.legalInformation;
    return (React.createElement(Paper, { component: "footer", sx: { padding: 2 } },
        React.createElement(Container, null,
            React.createElement(Stack, { spacing: 1 },
                React.createElement(List, { disablePadding: true, sx: { display: "flex", gap: 2 } }, links.map(function (_a) {
                    var href = _a.href, label = _a.label;
                    return (React.createElement(ListItem, { key: label, disableGutters: true, disablePadding: true, sx: { width: "fit-content" } },
                        React.createElement(Link, { href: href, target: "_blank", rel: "noopener" }, label)));
                })),
                React.createElement(Typography, { variant: "caption", lineHeight: 1.2, paragraph: true, color: "textSecondary" }, legalInformation)))));
};
export { GlobalFooter };
