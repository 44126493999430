import { Box, Container, Typography, useMediaQuery, useTheme, } from "@mui/material";
import React from "react";
import { DesktopStepper } from "components/DesktopStepper";
import { MobileStepper } from "components/MobileStepper";
var RegistrationHeader = function (_a) {
    var stepperTitle = _a.stepperTitle, currentStepLabel = _a.currentStepLabel, completedStepLabel = _a.completedStepLabel, registerTitle = _a.registerTitle, pageTitle = _a.pageTitle, signUpTitle = _a.signUpTitle, verifyEmailTitle = _a.verifyEmailTitle, accountDetailsTitle = _a.accountDetailsTitle, verifyingAccountTitle = _a.verifyingAccountTitle, stepperLabel = _a.stepperLabel, progressValue = _a.progressValue, activeStep = _a.activeStep;
    var theme = useTheme();
    var matches = useMediaQuery(theme.breakpoints.down("lg"));
    return (React.createElement(Box, { bgcolor: function (theme) { return theme.palette.primary.main; }, mt: function (theme) { return "-".concat(theme.spacing(0.5)); }, color: "white", paddingY: 2 },
        React.createElement(Container, null,
            React.createElement(Box, { display: "flex", gap: { lg: 1 }, justifyContent: { xs: "space-between", lg: "unset" }, alignItems: { xs: "end", lg: "unset" }, flexDirection: { xs: "row", lg: "column" } },
                React.createElement(Box, null,
                    React.createElement(Typography, { lineHeight: 1, gutterBottom: true, sx: function (theme) { return ({
                            fontSize: {
                                xs: theme.typography.body1.fontSize,
                                lg: theme.typography.h6.fontSize,
                            },
                        }); } }, registerTitle),
                    matches && (React.createElement(Typography, { variant: "h5", lineHeight: 1 }, pageTitle))),
                React.createElement(Box, null, matches ? (React.createElement(MobileStepper, { stepperLabel: stepperLabel, progressValue: progressValue })) : (React.createElement(DesktopStepper, { activeStep: activeStep, signUpTitle: signUpTitle, verifyEmailTitle: verifyEmailTitle, accountDetailsTitle: accountDetailsTitle, verifyingAccountTitle: verifyingAccountTitle, stepperTitle: stepperTitle, currentStepLabel: currentStepLabel, completedStepLabel: completedStepLabel })))))));
};
export { RegistrationHeader };
