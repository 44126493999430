import { Box, Button, List, Paper, Stack } from "@mui/material";
import React, { useState } from "react";
import { PasswordConstraint } from "components/PasswordConstraint/PasswordConstraint";
import { TextField } from "components/TextField";
var LoginDetails = function (props) {
    var _a = useState(false), hasAtLeastEightChar = _a[0], setHasAtLeastEightChar = _a[1];
    var _b = useState(false), hasAtLeastOneLowerChar = _b[0], setHasAtLeastOneLowerChar = _b[1];
    var _c = useState(false), hasAtLeastOneUpperChar = _c[0], setHasAtLeastOneUpperChar = _c[1];
    var _d = useState(false), hasAtLeastOneNumber = _d[0], setHasAtLeastOneNumber = _d[1];
    var _e = useState(false), hasAtLeastOneSpecialChar = _e[0], setHasHasAtLeastOneSpecialChar = _e[1];
    return (React.createElement(Paper, { sx: { padding: 3 } },
        React.createElement(Stack, { spacing: 3 },
            React.createElement("form", { id: "kc-register-form", action: props.formAction, method: "post", "aria-label": "Login Details" },
                React.createElement(Stack, { spacing: 2 },
                    React.createElement(Box, null,
                        React.createElement(TextField, { name: "email", label: props.emailAddressLabel, helperText: props.emailAddressErrMsg, defaultValue: props.emailAddressVal })),
                    React.createElement(Box, null,
                        React.createElement(TextField, { type: "password", name: "password", label: props.passwordLabel, helperText: props.passwordErrMsg, onChange: function (_a) {
                                var value = _a.target.value;
                                var specialChars = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~£]/;
                                if (value.length >= 8) {
                                    setHasAtLeastEightChar(true);
                                }
                                else {
                                    setHasAtLeastEightChar(false);
                                }
                                if (value.toUpperCase() !== value) {
                                    setHasAtLeastOneLowerChar(true);
                                }
                                else {
                                    setHasAtLeastOneLowerChar(false);
                                }
                                if (value.toLowerCase() !== value) {
                                    setHasAtLeastOneUpperChar(true);
                                }
                                else {
                                    setHasAtLeastOneUpperChar(false);
                                }
                                if (/\d/.test(value)) {
                                    setHasAtLeastOneNumber(true);
                                }
                                else {
                                    setHasAtLeastOneNumber(false);
                                }
                                if (specialChars.test(value)) {
                                    setHasHasAtLeastOneSpecialChar(true);
                                }
                                else {
                                    setHasHasAtLeastOneSpecialChar(false);
                                }
                            } })),
                    React.createElement(List, { subheader: props.passwordPolicyHeading },
                        React.createElement(PasswordConstraint, { constraint: props.passwordContraintEightCharMsg, satisfied: hasAtLeastEightChar }),
                        React.createElement(PasswordConstraint, { constraint: props.passwordPolicyOneLowerCharMsg, satisfied: hasAtLeastOneLowerChar }),
                        React.createElement(PasswordConstraint, { constraint: props.passwordPolicyOneUpperCharMsg, satisfied: hasAtLeastOneUpperChar }),
                        React.createElement(PasswordConstraint, { constraint: props.passwordPolicyOneNumberCharMsg, satisfied: hasAtLeastOneNumber }),
                        React.createElement(PasswordConstraint, { constraint: props.passwordPolicyOneSpecCharMsg, satisfied: hasAtLeastOneSpecialChar })),
                    React.createElement(Box, null,
                        React.createElement(TextField, { type: "password", name: "password-confirm", label: props.confirmPasswordLabel, helperText: props.confirmPasswordErrMsg })),
                    React.createElement(Button, { variant: "contained", type: "submit", sx: { width: "fit-content" } }, props.submitBtnLabel))))));
};
export { LoginDetails };
