import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import PaymentOutlinedIcon from "@mui/icons-material/PaymentOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { Alert, AlertTitle, Box, Button, Link, List, ListItem, Paper, Stack, Typography, useMediaQuery, useTheme, } from "@mui/material";
import { TextField } from "components/TextField";
import React from "react";
var LoginForm = function (props) {
    var theme = useTheme();
    var matches = useMediaQuery(theme.breakpoints.down("lg"));
    return (React.createElement(Box, { display: "grid", gridTemplateAreas: {
            xs: "'form' 'benefits'",
            lg: "'form benefits'",
        }, gridTemplateColumns: { xs: "1fr", lg: "1fr 1fr" }, gridTemplateRows: "auto", gap: 4, columnGap: { lg: 7 } },
        React.createElement(Box, { gridArea: "form" },
            React.createElement(Paper, { sx: {
                    padding: 3,
                } },
                React.createElement(Box, { mb: 3 },
                    React.createElement(Stack, { spacing: 1 },
                        React.createElement(Typography, { fontWeight: "bold" }, props.signInTitle),
                        props.errorMessage && (React.createElement(Alert, { severity: "error", sx: { width: "100%" } }, props.errorMessage)),
                        props.infoMessage && (React.createElement(Alert, { severity: "info", sx: { width: "100%" } }, props.infoMessage)),
                        React.createElement(Typography, null,
                            props.firstTimeHereText,
                            " ",
                            React.createElement(Link, { href: props.registerLinkHref }, props.registerLinkText)))),
                React.createElement(Stack, { spacing: 2 },
                    React.createElement("form", { action: props.formAction, method: "post" },
                        React.createElement(Stack, { spacing: 3 },
                            React.createElement(TextField, { label: props.emailAddressLabel, name: "username" }),
                            React.createElement(TextField, { label: props.passwordLabel, name: "password", type: "password" }),
                            React.createElement(Box, null,
                                React.createElement(Button, { variant: "contained", type: "submit", fullWidth: matches }, props.signInButtonLabel)))),
                    React.createElement(Link, { href: props.resetPasswordHref, width: { xs: "100%", lg: "initial" }, textAlign: matches ? "center" : "initial" }, props.resetPasswordLinkText),
                    Boolean(props.importantInformation) && (React.createElement(Box, { marginBottom: 4 },
                        React.createElement(Alert, { severity: "info", "aria-label": "Important Information" },
                            React.createElement(AlertTitle, null, "Important Information"),
                            React.createElement(Box, { dangerouslySetInnerHTML: {
                                    __html: props.importantInformation,
                                }, sx: {
                                    "p + p": {
                                        marginTop: 2,
                                    },
                                } }))))))),
        React.createElement(Box, { gridArea: "benefits" },
            React.createElement(Paper, { sx: { padding: 3, height: "100%" } },
                React.createElement(Typography, { paragraph: true }, props.summaryBlurb),
                React.createElement(List, { subheader: React.createElement(Typography, { variant: "overline" }, props.topFeaturesTitle) },
                    React.createElement(ListItem, { disableGutters: true },
                        React.createElement(Box, { display: "flex", gap: 1 },
                            React.createElement(AccountBalanceIcon, { color: "primary", fontSize: "medium" }),
                            React.createElement(Typography, null, props.featureMonitorBalanceAndRepayments))),
                    React.createElement(ListItem, { disableGutters: true },
                        React.createElement(Box, { display: "flex", gap: 1 },
                            React.createElement(CalendarMonthIcon, { color: "primary", fontSize: "medium" }),
                            React.createElement(Typography, null, props.featureManageRegularPayments))),
                    React.createElement(ListItem, { disableGutters: true },
                        React.createElement(Box, { display: "flex", gap: 1 },
                            React.createElement(PaymentOutlinedIcon, { color: "primary", fontSize: "medium" }),
                            React.createElement(Typography, null, props.featureMakePayment))),
                    React.createElement(ListItem, { disableGutters: true },
                        React.createElement(Box, { display: "flex", gap: 1 },
                            React.createElement(PersonOutlineOutlinedIcon, { color: "primary", fontSize: "medium" }),
                            React.createElement(Typography, null, props.featureManageContactDetails))))))));
};
export { LoginForm };
