import React from "react";
import Container from "@mui/material/Container";
import { AppBar, Link, Toolbar, Box } from "@mui/material";
var GlobalHeader = function (_a) {
    var logoUrl = _a.logoUrl, logoAlt = _a.logoAlt, signInHref = _a.signInHref, signInLabel = _a.signInLabel;
    return (React.createElement(AppBar, { component: "div", elevation: 0, position: "sticky", color: "inherit", sx: function (theme) { return ({
            borderBottom: "".concat(theme.spacing(0.5), " solid ").concat(theme.palette.primary.main),
        }); } },
        React.createElement(Toolbar, { disableGutters: true },
            React.createElement(Container, { sx: {
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                } },
                React.createElement(Box, { height: "50px" },
                    React.createElement("img", { src: logoUrl, alt: logoAlt, width: "auto", height: "100%" })),
                signInHref && React.createElement(Link, { href: signInHref }, signInLabel)))));
};
export { GlobalHeader };
