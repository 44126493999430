import React from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
var MobileStepper = function (_a) {
    var stepperLabel = _a.stepperLabel, progressValue = _a.progressValue;
    return (React.createElement(Box, null,
        React.createElement(Box, { position: "relative", height: "2rem", width: "2rem", m: 0.25 },
            React.createElement(CircularProgress, { "aria-labelledby": "registration-step", sx: {
                    top: 0,
                    left: 0,
                    color: "white",
                    position: "absolute",
                    zIndex: 999,
                }, variant: "determinate", value: progressValue, size: "2rem" }),
            React.createElement(CircularProgress, { "aria-hidden": true, variant: "determinate", value: 100, size: "2rem", sx: {
                    top: 0,
                    left: 0,
                    color: "white",
                    opacity: 0.2,
                    position: "absolute",
                } })),
        React.createElement(Typography, { variant: "body2", id: "registration-step" }, stepperLabel)));
};
export { MobileStepper };
