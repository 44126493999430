import React from "react";
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
var PasswordConstraint = function (_a) {
    var constraint = _a.constraint, satisfied = _a.satisfied;
    return (React.createElement(ListItem, { dense: true },
        React.createElement(ListItemIcon, { sx: { minWidth: "2rem" } }, satisfied ? (React.createElement(CheckCircleOutlineIcon, { color: "success", titleAccess: "Constraint satisfied" })) : (React.createElement(CancelOutlinedIcon, { color: "error", titleAccess: "Constraint not satisfied" }))),
        React.createElement(ListItemText, null, constraint)));
};
export { PasswordConstraint };
