import { Button, Paper, Typography, Stack } from "@mui/material";
import React from "react";
var Info = function (_a) {
    var guidanceText = _a.guidanceText, buttonLabel = _a.buttonLabel, buttonHref = _a.buttonHref;
    return (React.createElement(Paper, { sx: { padding: 3 } },
        React.createElement(Stack, { spacing: 2 },
            React.createElement(Typography, null, guidanceText),
            buttonHref && buttonLabel && (React.createElement(Button, { variant: "contained", href: buttonHref, sx: { width: { xs: "100%", sm: "fit-content" } } }, buttonLabel)))));
};
export { Info };
