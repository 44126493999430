import { Paper, Typography, Link, Stack } from "@mui/material";
import React from "react";
var VerifyEmail = function (_a) {
    var primaryText = _a.primaryText, secondaryText = _a.secondaryText, tertiaryText = _a.tertiaryText, resendEmailLabel = _a.resendEmailLabel, resendEmailHref = _a.resendEmailHref;
    return (React.createElement(Paper, { sx: { padding: 3 } },
        React.createElement(Stack, { spacing: 2 },
            React.createElement(Typography, null, primaryText),
            React.createElement(Typography, null, secondaryText),
            React.createElement(Typography, null, tertiaryText),
            React.createElement(Link, { href: resendEmailHref }, resendEmailLabel))));
};
export { VerifyEmail };
