import React from "react";
import { Paper, Typography, Stack, Button } from "@mui/material";
import { TextField } from "components/TextField";
var SendResetPasswordInstructions = function (props) {
    return (React.createElement(Paper, { sx: { padding: 3 } },
        React.createElement(Stack, { spacing: 3 },
            React.createElement(Typography, null, props.guidanceText),
            React.createElement("form", { action: props.formAction, method: "post" },
                React.createElement(Stack, { spacing: 2 },
                    React.createElement(TextField, { name: "username", label: props.emailLabel, helperText: props.emailAddressErrMsg }),
                    React.createElement(Button, { type: "submit", variant: "contained", sx: { width: { xs: "100%", sm: "fit-content" } } }, props.submitBtnLabel))))));
};
export { SendResetPasswordInstructions };
