import React from "react";
import { ConfirmationLayout } from "containers/ConfirmationLayout";
import { ConfirmationVariant } from "containers/ConfirmationLayout/ConfirmationLayout";
import { Typography } from "@mui/material";
var ResetPasswordSuccess = function (props) {
    return (React.createElement(ConfirmationLayout, { linksHeading: props.whatNextHeading, links: [
            { label: props.homepageLinkText, href: props.formAction, asForm: true },
        ], variant: ConfirmationVariant.Successful },
        React.createElement(Typography, { variant: "h5", fontWeight: 600, gutterBottom: true }, props.feedbackLabel),
        React.createElement(Typography, null, props.resetPasswordGuidance)));
};
export { ResetPasswordSuccess };
