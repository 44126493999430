import React from "react";
import { Paper, Stack, Button } from "@mui/material";
import { TextField } from "components/TextField";
var FinancialAccountDetails = function (props) {
    return (React.createElement(Paper, { sx: { padding: 3 } },
        React.createElement("form", { action: props.formAction, method: "post" },
            React.createElement(Stack, { spacing: 3 },
                React.createElement(TextField, { name: "accountReference", label: props.accountReferenceLabel, helperText: props.accountReferenceErrMsg }),
                React.createElement(TextField, { name: "lastName", label: props.lastNameLabel, helperText: props.lastNameErrMsg }),
                React.createElement(TextField, { type: "date", name: "dateOfBirth", label: props.dobLabel, helperText: props.dobErrMsg }),
                React.createElement(TextField, { name: "postcode", label: props.postcodeLabel, helperText: props.postcodeErrMsg }),
                React.createElement(Button, { type: "submit", variant: "contained", sx: { width: { xs: "100%", sm: "fit-content" } } }, props.submitBtnLabel)))));
};
export { FinancialAccountDetails };
