import { Button, List, Paper, Stack } from "@mui/material";
import React, { useState } from "react";
import { PasswordConstraint } from "components/PasswordConstraint";
import { TextField } from "components/TextField";
var SetNewPassword = function (props) {
    var _a = useState(false), hasAtLeastEightChar = _a[0], setHasAtLeastEightChar = _a[1];
    var _b = useState(false), hasAtLeastOneLowerChar = _b[0], setHasAtLeastOneLowerChar = _b[1];
    var _c = useState(false), hasAtLeastOneUpperChar = _c[0], setHasAtLeastOneUpperChar = _c[1];
    var _d = useState(false), hasAtLeastOneNumber = _d[0], setHasAtLeastOneNumber = _d[1];
    var _e = useState(false), hasAtLeastOneSpecialChar = _e[0], setHasHasAtLeastOneSpecialChar = _e[1];
    return (React.createElement(Paper, { sx: { padding: 3 } },
        React.createElement("form", { action: props.formAction, method: "post" },
            React.createElement(Stack, { spacing: 2 },
                React.createElement(TextField, { name: "username", value: props.usernameVal, sx: { display: "none" }, InputProps: { readOnly: true } }),
                React.createElement(TextField, { type: "password", name: "password-new", label: props.passwordLabel, helperText: props.passwordErrMsg, onChange: function (_a) {
                        var value = _a.target.value;
                        var specialChars = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~£]/;
                        if (value.length >= 8) {
                            setHasAtLeastEightChar(true);
                        }
                        else {
                            setHasAtLeastEightChar(false);
                        }
                        if (value.toUpperCase() !== value) {
                            setHasAtLeastOneLowerChar(true);
                        }
                        else {
                            setHasAtLeastOneLowerChar(false);
                        }
                        if (value.toLowerCase() !== value) {
                            setHasAtLeastOneUpperChar(true);
                        }
                        else {
                            setHasAtLeastOneUpperChar(false);
                        }
                        if (/\d/.test(value)) {
                            setHasAtLeastOneNumber(true);
                        }
                        else {
                            setHasAtLeastOneNumber(false);
                        }
                        if (specialChars.test(value)) {
                            setHasHasAtLeastOneSpecialChar(true);
                        }
                        else {
                            setHasHasAtLeastOneSpecialChar(false);
                        }
                    } }),
                React.createElement(List, { subheader: props.passwordPolicyHeading },
                    React.createElement(PasswordConstraint, { constraint: props.passwordContraintEightCharMsg, satisfied: hasAtLeastEightChar }),
                    React.createElement(PasswordConstraint, { constraint: props.passwordPolicyOneLowerCharMsg, satisfied: hasAtLeastOneLowerChar }),
                    React.createElement(PasswordConstraint, { constraint: props.passwordPolicyOneUpperCharMsg, satisfied: hasAtLeastOneUpperChar }),
                    React.createElement(PasswordConstraint, { constraint: props.passwordPolicyOneNumberCharMsg, satisfied: hasAtLeastOneNumber }),
                    React.createElement(PasswordConstraint, { constraint: props.passwordPolicyOneSpecCharMsg, satisfied: hasAtLeastOneSpecialChar })),
                React.createElement(TextField, { type: "password", name: "password-confirm", label: "Confirm password", helperText: props.confirmPasswordErrMsg }),
                React.createElement(Button, { type: "submit", variant: "contained", sx: { width: { xs: "100%", sm: "fit-content" } } }, props.submitBtnLabel)))));
};
export { SetNewPassword };
