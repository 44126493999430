import React from "react";
import { Typography } from "@mui/material";
import { ConfirmationLayout } from "containers/ConfirmationLayout";
var VerifyingAccount = function (props) {
    return (React.createElement(React.Fragment, null,
        React.createElement(ConfirmationLayout, null,
            React.createElement(Typography, { variant: "h5", fontWeight: 600, gutterBottom: true }, props.whatHappensNextHeading),
            React.createElement(Typography, null, props.whatHappensNextGuidance1),
            React.createElement(Typography, null, props.whatHappensNextGuidance2),
            React.createElement(Typography, null, props.whatHappensNextGuidance3))));
};
export { VerifyingAccount };
