import { Box, Paper, Stack, List, ListItem, Typography } from "@mui/material";
import { amber, green } from "@mui/material/colors";
import { LinkButton } from "components/LinkButton";
import DoneIcon from "@mui/icons-material/Done";
import ScheduleIcon from "@mui/icons-material/Schedule";
import React from "react";
var ConfirmationVariant;
(function (ConfirmationVariant) {
    ConfirmationVariant["Pending"] = "Pending";
    ConfirmationVariant["Successful"] = "Successful";
    ConfirmationVariant["Unsuccessful"] = "Unsuccessful";
})(ConfirmationVariant || (ConfirmationVariant = {}));
var ConfirmationLayout = function (_a) {
    var linksHeading = _a.linksHeading, _b = _a.variant, variant = _b === void 0 ? ConfirmationVariant.Pending : _b, links = _a.links, children = _a.children;
    return (React.createElement(Stack, { spacing: 3 },
        React.createElement(Paper, { sx: { paddingX: { xs: 2, lg: 5 }, paddingY: 3 } },
            React.createElement(Box, { display: "grid", gridTemplateColumns: { xs: "1fr", lg: "min-content 1fr" }, gap: { xs: 2, lg: 4 } },
                React.createElement(Box, null,
                    variant === ConfirmationVariant.Pending && (React.createElement(Box, { sx: {
                            padding: 1,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor: amber[500],
                        } },
                        React.createElement(ScheduleIcon, { sx: { color: "white", fontSize: 50 } }))),
                    variant === ConfirmationVariant.Successful && (React.createElement(Box, { sx: {
                            padding: 1,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor: green[500],
                        } },
                        React.createElement(DoneIcon, { sx: { color: "white", fontSize: 50 } })))),
                React.createElement(Box, null, children))),
        links && (React.createElement(Box, null,
            React.createElement(Typography, { variant: "h5", gutterBottom: true }, linksHeading),
            React.createElement(List, { disablePadding: true }, links.map(function (_a) {
                var label = _a.label, href = _a.href, asForm = _a.asForm;
                return asForm ? (React.createElement(ListItem, { key: label, disableGutters: true },
                    React.createElement(Box, { sx: { width: "100%" } },
                        React.createElement("form", { action: href, method: "post" },
                            React.createElement(LinkButton, { label: label }))))) : (React.createElement(ListItem, { key: label, disableGutters: true },
                    React.createElement(LinkButton, { label: label, href: href })));
            }))))));
};
export { ConfirmationLayout, ConfirmationVariant };
