import { Button } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import React from "react";
var LinkButton = function (_a) {
    var label = _a.label, href = _a.href;
    return (React.createElement(Button, { component: href ? "a" : "button", type: "submit", variant: "contained", size: "large", href: href, endIcon: React.createElement(ArrowForwardIcon, null), sx: {
            width: {
                xs: "100%",
                sm: "20rem",
                justifyContent: "space-between",
            },
        } }, label));
};
export { LinkButton };
